import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import dynamic from 'next/dynamic';

import CarouselSection from '~/app/components/ItemPage/sections/CarouselSection';
import { PageSectionTypes } from '~/app/components/ItemPage/sections/types';
import ItemPageMetadata from '~/app/components/ItemPage/ItemPageMetadata';
import SectionRenderer from '~/app/components/ItemPage/SectionRenderer';
import useIsLargeScreen from '~/app/lib/hooks/useIsLargeScreen';
import useSelectArtist from '~/app/lib/hooks/useSelectArtist';
import { PageTrackerContext } from '~/app/components/Page';
import withLazyModal from '~/app/lib/hocs/withLazyModal';
import { toVhValue } from '~/app/components/Vh';
import { useAppRouter } from '~/app/lib/router2';
import ItemPage from '~/app/components/ItemPage';
import useHash from '~/app/lib/hooks/useHash';
import { useI18n } from '~/app/lib/i18n';
import Box from '~/app/components/Box';
import { PageTypes } from '~/types';

import ItemPageNav, {
  ItemPageNavProvider,
} from '~/app/components/ItemPage/ItemPageNav';

import {
  LoadingObserver,
  toObservedLoadingComponent,
} from '~/app/components/ItemPage/LoadingObserver';

import {
  LayoutSlotIds,
  MAX_CONTENT_WIDTH,
} from '~/app/components/ItemPage/constants';

import useArtistItemContext from './useArtistItemContext';
import TitleLinksSection from './lib/TitleLinksSection';
import toStructuredData from './toStructuredData';
import useArtistChecks from './useArtistChecks';
import ScrollPrompt from './lib/ScrollPrompt';

const MerchDynamic = dynamic(
  () => import('~/app/components/ItemPage/sections/MerchSection'),
  {
    loading: toObservedLoadingComponent(),
  }
);

const ReleasesSectionDynamic = dynamic(
  () => import('~/app/components/ItemPage/sections/ReleasesSection'),
  {
    loading: toObservedLoadingComponent(),
  }
);

const VideosSectionDynamic = dynamic(
  () => import('~/app/components/ItemPage/sections/VideosSection'),
  {
    loading: toObservedLoadingComponent(),
  }
);

const ShowsSectionDynamic = dynamic(
  () => import('~/app/components/ItemPage/sections/ShowsSection'),
  {
    loading: toObservedLoadingComponent(),
  }
);

const OrchardLegalFooterDynamic = dynamic(
  () => import('~/app/components/ItemPage/OrchardLegalFooter'),
  { ssr: true }
);

const ClaimSuccessDialogLazy = withLazyModal<any>(
  () => import('./ClaimSuccessDialog')
);

const PAGE_SECTION_COMPONENTS = {
  [PageSectionTypes.VIDEOS]: VideosSectionDynamic,
  [PageSectionTypes.SHOWS]: ShowsSectionDynamic,
  [PageSectionTypes.MERCH]: MerchDynamic,
  [PageSectionTypes.RELEASES]: ReleasesSectionDynamic,
  [PageSectionTypes.CAROUSEL]: CarouselSection,
  [PageSectionTypes.TITLE_LINKS]: TitleLinksSection,
};

const ArtistPage: FC<{
  artistId: number;
}> = ({ artistId }) => {
  const { claimSuccess } = useAppRouter().getAsQuery();
  const isLargeScreen = useIsLargeScreen();
  const { t } = useI18n();
  const { setHashParam } = useHash();
  const scrollPromptApiRef = useRef<() => void>(null);

  const [claimSuccessDialogOpen, setClaimSuccessDialogOpen] = useState(
    !!claimSuccess
  );

  const artist = useSelectArtist(artistId)!;
  const itemContext = useArtistItemContext({ artist });

  useArtistChecks(artist);

  return (
    <LoadingObserver>
      <ItemPageNavProvider>
        <ItemPage
          key={artistId}
          pageType={PageTypes.ARTIST}
          itemContext={itemContext!}
          hasContent={!!artist.links}
          testId="itemPageArtist"
          displayType="artist"
          error={artist.error}
          pageOwnedByAccountIds={artist.ownedByAccountIds}
          isLoading={!!artist.isLoading}
          isOwned={artist.isOwned}
          isOrchardPage={artist.isOrchardPage}
          pageBrand={artist.pageBrand}
          userCanEdit={!!artist.userCanEdit}
          heroHeight={isLargeScreen ? '84vh' : toVhValue(88)}
          backgroundHeight={isLargeScreen ? '100%' : toVhValue(84)}
          trackerContext={useMemo(
            (): PageTrackerContext => ({
              artistId: artist.id,
              artistName: artist.name,
            }),
            [artist]
          )}
          shareText={artist.name}
          pagePath={artist.pagePath}
          artistPagePath={artist.pagePath}
          pageSectionComponents={PAGE_SECTION_COMPONENTS}
          onScroll={useCallback(() => {
            if (scrollPromptApiRef.current) {
              scrollPromptApiRef.current();
            }
          }, [])}
          toActionItems={useCallback(
            ({ sections }) => {
              if (artist.userCanEdit) {
                sections[0].push({
                  content: t('item.actions.viewInDashboard'),
                  href: `/dashboard/artist/${artist.id}`,
                  icon: 'chart',
                  testId: 'viewInDashboard',
                });
              } else {
                // If the user doesn't own the artist,
                // they can try to claim it if
                sections[0].push({
                  content: t('item.actions.claimArtist'),
                  icon: 'verified',
                  testId: 'claimArtistOption',
                  onClick: () => {
                    setHashParam({ claimPage: '' });
                  },
                });
              }

              return sections;
            },
            [artist]
          )}
          renderHeroContent={useCallback(() => {
            const items = itemContext?.layout.hero;

            if (!items) {
              return null;
            }

            return (
              <Box
                style={{
                  margin: 'auto auto 2rem',
                  width: '100%',
                  maxWidth: MAX_CONTENT_WIDTH,
                  padding: '0 1.2rem',
                }}
              >
                {items.map((item, index) => {
                  return (
                    <SectionRenderer
                      key={`${item.component || item.preset}${index}`}
                      components={PAGE_SECTION_COMPONENTS}
                      sectionPath={`${LayoutSlotIds.HERO}[${index}]`}
                      sectionIndex={index}
                      itemContext={itemContext}
                      pageSection={item}
                    />
                  );
                })}
              </Box>
            );
          }, [itemContext])}
          renderFooterContent={useCallback(() => {
            if (artist.isOrchardPage) {
              return (
                <>
                  <OrchardLegalFooterDynamic />
                </>
              );
            }
          }, [])}
          renderContent={useCallback(() => {
            return (
              <>
                {claimSuccessDialogOpen && (
                  <ClaimSuccessDialogLazy
                    isOpen={claimSuccessDialogOpen}
                    onClose={() => setClaimSuccessDialogOpen(false)}
                    artistId={artistId}
                  />
                )}
                <ItemPageMetadata
                  error={artist.error}
                  pagePath={artist.pagePath}
                  itemContext={itemContext!}
                  isOwned={artist.isOwned}
                  defaultTitle={artist.name}
                  defaultImage={artist.image}
                  defaultDescription={
                    artist.name &&
                    t('item.artistPageDescription', { artistName: artist.name })
                  }
                  toStructuredData={(params: any) =>
                    toStructuredData({
                      ...params,
                      artistName: artist.name,
                    })
                  }
                />
                <ScrollPrompt apiRef={scrollPromptApiRef} />
              </>
            );
          }, [claimSuccessDialogOpen, artist, isLargeScreen])}
          // COMPLEX: <ItemPageNav> must be rendered *after* all the page sections
          // so that they can register their NavItems *before* <ItemPageNav> is
          // rendered. If not then the SSR html won't contain any nav items.
          // We have exposed this special option to ensure <itemPageNav> appears
          // in the correct order of the react component tree.
          withBeforeContentAfterMain
          renderHeaderContent={useCallback(() => {
            return <ItemPageNav />;
          }, [isLargeScreen])}
        />
      </ItemPageNavProvider>
    </LoadingObserver>
  );
};

export default ArtistPage;
